// const globeGif = require('./lib/Styles/globe.gif');
const polyfill = require("terriajs/lib/Core/polyfill");

require('./lib/Styles/loader.css');
require('./lib/Styles/stars.css');
require('./lib/Styles/cube.css');

import parser from "fast-xml-parser";

// worker
import registerServiceWorker, {
  ServiceWorkerNoSupportError
} from "./lib/workers/storage.worker.ts";

registerServiceWorker({ scope: "/" })
  .then(registration => {
    console.log("register Service Worker Success!", registration);
    return () => {
      registration.unregister().then(flag => {
        console.log("unregister success ?", flag);
      });
    };
  })
  .catch(err => {
    if (err instanceof ServiceWorkerNoSupportError) {
      console.log("Service worker is not supported.");
    } else {
      console.log("Error!");
    }
  });

navigator.serviceWorker.addEventListener("message", event => {
  // event は MessageEvent オブジェクトらしいです
  if (event.data.action == "assumeRoleCredentials") {
    const assumeRoleCredentials = event.data.assumeRoleCredentials;
    // ここからメイン処理
    createLoader();
  }
});

const assumeRoleToServiceWorker = async tokens => {
  if (!tokens) return;

  const headers = new Headers({
    "Content-Type": "application/xml; charset=utf-8"
  });
  const params = {
    // 渡したいパラメータをJSON形式で書く
    Action: "AssumeRoleWithWebIdentity",
    DurationSeconds: "604800",
    WebIdentityToken: tokens.token,
    Version: "2011-06-15"
  };
  const query_params = new URLSearchParams(params);
  const response = await fetch(
    process.env.REACT_APP_MINIO_URL + "?" + query_params,
    {
      method: "POST",
      // body: JSON.stringify(body),
      headers: headers
    }
  );

  const body = await response.text();
  const parsed = parser.parse(body);
  const assumeRoleCredentials =
    parsed.AssumeRoleWithWebIdentityResponse.AssumeRoleWithWebIdentityResult
      .Credentials;

  navigator.serviceWorker.controller?.postMessage({
    assumeRoleCredentials: assumeRoleCredentials,
    token: tokens.token // RS256 aud: tomap-graphql ?
  });
};

// login check
const keycloak = require('./lib/keycloak').default;


// これは keycloak.init で処理しよう
// keycloak.onAuthSuccess = (e) => {
//   console.log('hoge onAuthSuccess', e, keycloak.token);
// };
keycloak.onAuthRefreshSuccess = (e) => {
  assumeRoleToServiceWorker(keycloak);
};

// TODO: ログアウト処理
keycloak.onAuthLogout = (e) => {
  // alert('logout');
  location.reload();
};

if (process.env.REACT_APP_KEYCLOAK_FRONTEND_URL) {
  keycloak.init({ onLoad: "login-required" }).then((r) => {
    // console.log(r); // 原則 true
    assumeRoleToServiceWorker(keycloak);
    // keycloak.updateToken(300); // TODO: refresh
  });
}else {
  createLoader();
}

////////////////////////////////////////////////////////////////////////////

function loadMainScript() {
  // load the main chunk
  return new Promise((resolve, reject) => {
    require.ensure(['terriajs/lib/Core/prerequisites'], function (require) {
      require('terriajs/lib/Core/prerequisites');
      require.ensure(['./index'], function (require) {
        resolve(require('./index'));
      }, reject, 'index');
    }, reject, 'index');
  });
}

function createLoader() {
  const loaderDiv = document.createElement('div');
  loaderDiv.classList.add("loader-ui");
  // const loaderGif = document.createElement('img');
  // loaderGif.src = globeGif;
  const loaderLeft = document.createElement('div');
  loaderLeft.classList.add("loader-ui-left");
  const loaderGrabber = document.createElement('div');
  loaderGrabber.classList.add('loader-ui-grabber');
  const loaderRight = document.createElement('div');
  loaderRight.classList.add("loader-ui-right");
  // loaderRight.appendChild(loaderGif);

  const stars = document.createElement('div');
  stars.id = "stars";
  const stars2 = document.createElement('div');
  stars2.id = "stars2";
  const stars3 = document.createElement('div');
  stars3.id = "stars3";
  loaderDiv.appendChild(stars);
  loaderDiv.appendChild(stars2);
  loaderDiv.appendChild(stars3);

  const cube = document.createElement('div');
  cube.classList.add('cube');
  const half1 = document.createElement('div');
  half1.classList.add('half1');
  const s1 = document.createElement('div');
  s1.classList.add("side", "s1");
  const s2 = document.createElement('div');
  s2.classList.add("side", "s2");
  const s5 = document.createElement('div');
  s5.classList.add("side", "s5");
  half1.appendChild(s1);
  half1.appendChild(s2);
  half1.appendChild(s5);
  const half2 = document.createElement('div');
  half2.classList.add('half2');
  const s3 = document.createElement('div');
  s3.classList.add("side", "s3");
  const s4 = document.createElement('div');
  s4.classList.add("side", "s4");
  const s6 = document.createElement('div');
  s6.classList.add("side", "s6");
  half2.appendChild(s3);
  half2.appendChild(s4);
  half2.appendChild(s6);
  cube.appendChild(half1);
  cube.appendChild(half2);
  const cubeContainer = document.createElement('div');
  cubeContainer.classList.add('container');
  cubeContainer.appendChild(cube);
  loaderRight.appendChild(cubeContainer);

  loaderDiv.appendChild(loaderLeft);
  loaderDiv.appendChild(loaderRight);
  loaderDiv.appendChild(loaderGrabber);
  loaderDiv.style.backgroundColor = '#000'; // '#383F4D';


  document.body.appendChild(loaderDiv);

  polyfill(function () {
    loadMainScript().catch(() => {
      // Ignore errors and try to show the map anyway
    }).then(() => {
      loaderDiv.classList.add('loader-ui-hide');
      setTimeout(() => {
        document.body.removeChild(loaderDiv);
      }, 2000);
    });
  });
}

// createLoader();
